import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BlocksRenderer from "../components/blocks-renderer"
import Headings from "../components/headings"

const PlaybooksPage = () => {
  const { allStrapiIssue } = useStaticQuery(graphql`
    query {
      allStrapiIssue(filter: {locale: {eq: "en"}}) {
        nodes {
          id
          description
          slug
          title
        }
      }
    }
  `)
  const issues = allStrapiIssue.nodes

  // const seo = {
  //   metaTitle: title,
  //   metaDescription: title,
  // }

  // return (
  //   <Layout>
  //     <Seo seo={seo} />
  //     <Headings title={strapiAbout.title} />
  //     <BlocksRenderer blocks={blocks} />
  //   </Layout>
  // )
  return (
    <Layout>
    </Layout>
  )
}

export default PlaybooksPage
